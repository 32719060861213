import React from 'react';
const { locationType, placeTypeWithIcon } = Dict.map;
const FrameCard = Loader.loadBusinessComponent("FrameCard");

class PlaceLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { labelVisible: false, }; // 标签弹窗
  }
  componentDidMount() {

  }
  changeLabelModal = () => {
    this.setState({
      labelVisible: true
    });
  };
  handleLabelCancel = () => {
    this.setState({
      labelVisible: false
    });
    this.props.handleLabelCancel()
  };
  handleLabelOK = () => {
    this.props.handleLabelOK()
    this.handleLabelCancel();
  };

  render() {
    let { initPlaceTags } = this.props;
    let allTags = locationType.concat(placeTypeWithIcon);
    return <FrameCard
        title="所在区域类型："
        // headerOperator={
        //   <div className="place-label-edit" onClick={this.changeLabelModal}>
        //     <IconFont type={"icon-S_Edit_Edit"} theme="outlined" /> 编辑
        //   </div>
        // }
      >
        <div className="place-label-view">
          {
            initPlaceTags&&initPlaceTags.length?
            initPlaceTags.map(v => {
              let item = allTags.find(i => {
                return i.value === v;
              });
              return (
                item && item.label&&
                <div className="place-label-item">
                  {
                    item.label
                  }
                </div>
              );
            })
            : <div className='no-label'>暂无标签</div>
          }
        </div>
      </FrameCard>; 
  }
}

export default PlaceLabel