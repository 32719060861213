import React from 'react';
import { observer } from 'mobx-react';
import ResourceSearch from './components/ResourceSearch';
import './style/index.less';

@observer
class ResourceTreeView extends React.Component {
  onSelectDevice = (data) => {
    this.props.onSelectDevice(data)
  }
  render() {
    const { deviceList, deviceInfo, placeList } = this.props;
    return (
      <div className="device-resource-list-view">
        <ResourceSearch
          placeList={placeList}
          deviceList={deviceList}
          selectDevice={deviceInfo}
          onSelectDevice={this.onSelectDevice}
        />
      </div>
    );
  }
}
export default ResourceTreeView