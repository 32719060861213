import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import SearchForm from './SearchForm';
import * as _ from 'lodash';
import { Button } from 'antd';
import DeviceContent from './DeviceContent'

import '../style/resouce-search.less';


@observer
class ResourceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isResource: true,
      form: {
        keyword: '',
        status: '',
        type: '',
        placeId: '',
      }
    };
  }
  changeForm = (options) => {
    let {form} = this.state
    if (form&&form.placeId) {
      options.placeId = form.placeId;
    }
      this.setState({ form: options });
  }

  filterList = (list, ids, key) => {
    const newList = list.filter(item => {
      let flag = false;
      const arrTemp = item[key] || [];
      for (let i = 0; i < arrTemp.length; i++) {
        if (ids.indexOf(arrTemp[i]) > -1) {
          flag = true;
          break;
        }
      }
      return flag;
    });
    return newList
  }

  filterPlaceList = (list, ids) => {
    const newList = list.filter(item => (
      ids.indexOf(item.placeId) > -1
    ))
    return newList
  }

  queryDevice(list, params) {
    const resourceMode = this.resourceMode || 'org';
    let { keyword, status, type, placeId } = params;
    keyword = _.trim(keyword);
    if (!keyword && !status && !type && !`${resourceMode}Id`) {
      return list;
    }

    let newList = list;
    if (!!placeId) {
      let placeIds = BaseStore.place.queryPlaceIdsForParentId(placeId);
      newList = this.filterPlaceList(list, placeIds);
    }

    !!keyword
      ? (newList = newList.filter(
          item => (
            (item.deviceName && item.deviceName.indexOf(keyword) > -1) 
            || item.sn === keyword || item.cid === keyword
          )
        ))
      : null;
    !!type && type !== '-1'
      ? (newList = newList.filter(item => {
        let typeArr = type.split(',')
        return item.deviceType * 1 === type * 1 || item.deviceType * 1 === typeArr[0] * 1 || item.deviceType * 1 === typeArr[1] * 1
      }))
      : null;

    !!status && status !== '-1'
      ? status * 1 === 0
        ? (newList = newList.filter(item => item.deviceStatus * 1 === 0))
        : (newList = newList.filter(item => item.deviceStatus * 1 === 1))
      : null;

    return newList;
  }
  
  changePlace = (placeId) => {
    const { form } = this.state;
    this.setState({ form: Object.assign({}, form, { placeId }) });
  }

  setResourceMode = (mode) => {
    this.resourceMode = mode;
  }

  render() {
    const { deviceList,onSelectDevice,selectDevice,placeList} = this.props;
    const { isResource, form } = this.state;
    const {
      isMapMode,
      showLoopSettingLayout,
      isLoop,
    } = this.props;
    const listDevice = this.queryDevice(deviceList, form);
    return (
      <div className="resource-search">
        <div
          className={`resource-part ${!isResource ? "hide-resource" : ""}`}
        >
          <SearchForm changeForm={this.changeForm} form={form} />
          <DeviceContent
            setResourceMode={this.setResourceMode}
            changePlace={this.changePlace}
            deviceList={listDevice}
            placeList={placeList}
            isMapMode={isMapMode}
            isLoop={isLoop}
            keyword={form.keyword}
            selectDevice={selectDevice}
            onSelectDevice={onSelectDevice}
            showLoopSettingLayout={showLoopSettingLayout}
            className="org-tree-with-device"
          />
        </div>
      </div>
    );
  }
}
export default ResourceSearch