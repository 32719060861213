import React from "react";
import DeviceInfo from "./components/DeviceInfo";
import PlaceLabel from "./components/PlaceLabel";
import DevicePoint from "./components/DevicePoint";
import SnapResource from "./components/SnapResource";
import ResourceStatistics from "./components/ResourceStatistics";
import VisitorsFlowrate from "./components/VisitorsFlowrate";
import DeviceResouceTree from "./components/DeviceResouceTree";
import { message, Spin } from "antd";
import { observer } from "mobx-react";
import "./index.less";

const NoData = Loader.loadBaseComponent("NoData");

@Decorator.businessProvider("user", "device", "place")
@Decorator.withEntryLog()
@observer
class deviceFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: [],
      placeList: [],
      deviceInfo: {},
      resources: {},
      vehiclesResources: {},
      placeTags: [],
      initPlaceTags: [],
      loading: true,
      countDeviceCapPersonsData: {},
      countDeviceCapPersonsType: 2,
      nearDeviceList: [],
      counResourceStatisticsType: 2,
      countSnapResourceType: 2,
      totalResource: [],
      vehicleResource: [],
      faceResource: [],
      bodyResource: [],
      snapResource: []
    };
    let { location } = this.props;
    let searchJson = Utils.queryFormat(location.search);
    this.cid = searchJson.id;
  }
  componentDidMount() {
    this.ininData().catch(e => {
      this.setState({ loading: false });
    });
  }

  ininData = async () => {
    const { user, place } = this.props;
    let deviceInfo = {};
    const cameraTypes = [];
    Dict.map.deviceType
      .filter(v => v.value !== "-1" && v.value !== "100605")
      .map(v => {
        let item = v.value.split(",");
        if (item.length > 1) {
          cameraTypes.push(item[0]);
          cameraTypes.push(item[1]);
        } else {
          cameraTypes.push(v.value);
        }
        return cameraTypes;
      });

    const placeIdRes = await Service.organization.getPlaceIdsByUserId({
      organizationId: user.userInfo.organizationId
    });
    let placeIds = placeIdRes.data.placeIds.filter(v => !!v);
    if (placeIds.length === 0) {
      this.setState({ loading: false });
      return;
    }

    const result = await Promise.all([
      Service.device.countPlaceDeviceStatus({
        deviceTypes: cameraTypes,
        placeIds,
        ignoreUserRestrictions: true
      }),
      Shared.queryUserDevice({
        ignoreUserRestrictions: true,
        placeIds,
        deviceTypes: cameraTypes,
        offset: 0,
        limit: 100000
      })
    ]);

    const [countPlaceRes, deviceMap] = result;
    const deviceList = Object.values(deviceMap.idMap);
    let placeList = Shared.computedPlaceDeviceCount(
      place.getPlaceInfoByIds(placeIds),
      countPlaceRes.data
    );
    if (deviceList.length === 0) {
      this.setState({ loading: false });
      return;
    }
    
    if (this.cid) {
      deviceInfo = deviceList.find(v => v.cid == this.cid);
    } else {
      deviceInfo = deviceList[0];
      this.cid = deviceInfo.cid;
    }
    
    if (!deviceInfo){
      this.setState({ loading: false });
      return message.warn("无该设备档案查看权限");
    }
      this.setState({
        placeList,
        deviceList
      });
    this.getInitDeviceData(deviceInfo).catch(() => {
      message.warn("数据加载失败");
      this.setState({ loading: false });
    });
  };
  removeDuplicateItems = arr => [...new Set(arr)];
  getInitDeviceData = async device => {
    const { cid, longitude, latitude } = device;
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    const logInfo = Service.url.request.viewDeviceFile;
    Service.logger.save({
      description: `查看设备【${device.deviceName || cid}】档案`,
      ...logInfo
    });

    const startTime = new Date() * 1 - (7 - 1) * 24 * 60 * 60 * 1000;
    const endTime = new Date() * 1;

    Service.device.queryDeviceInfoByCid(cid).then(res => {
      let pathId = res.data.pathId || [];
      let placeTags = res.data.placeTags || [];
      this.setState({
        deviceInfo: res.data,
        placeTags: pathId,
        initPlaceTags: pathId
      });
    });
    Service.place.countDeviceResources({ cids: [cid] }).then(res => {
      this.setState({
        resources: res.data
      });
    });
    Service.place.countVehiclesByCids({ cids: [cid] }).then(res => {
      this.setState({
        vehiclesResources: res.data
      });
    });
    Service.place
      .countDeviceCapPersons({
        cids: [cid],
        timeType: this.state.countDeviceCapPersonsType
      })
      .then(res => {
        this.setState({
          countDeviceCapPersonsData: res.data
        });
      });
    Service.statistics
      .countByDays({ cids: [cid], startTime, endTime })
      .then(res => {
        this.setState({
          vehicleResource: res.data
        });
      });
    Service.statistics
      .countPersonDayTrend({
        cids: [cid],
        type: 0,
        days: 7,
        privilege: 1
      })
      .then(res => {
        this.setState({
          faceResource: res.data.list,
          bodyResource: res.data.list
        });
      });
    Service.place
      .getDeviceStatisticByCid({ cid: cid, startTime, endTime })
      .then(res => {
        this.setState({
          totalResource: res.data.list,
          snapResource: res.data.list
        });
      });
    longitude &&
      latitude &&
      Service.place
        .queryDeviceByCenter({
          center: `${longitude},${latitude}`,
          distance: 500
        })
        .then(res => {
          this.setState({
            nearDeviceList: res ? res.data : []
          });
        });
    this.setState({
      loading: false
    });
    // this.counResourceStatistics(this.state.countDeviceCapPersonsType);
  };

  //人流量分布规律
  countDeviceCapPersons = timeType => {
    let { cid } = this.state.deviceInfo;
    Service.place
      .countDeviceCapPersons({
        cids: [cid],
        timeType
      })
      .then(res => {
        this.setState({
          countDeviceCapPersonsData: res.data
        });
      });
  };
  countDeviceCapPersonsTypeChange = v => {
    this.setState({ countDeviceCapPersonsType: v });
    this.countDeviceCapPersons(v);
  };
  //​抓拍资源数据走向
  counResourceStatistics = async v => {
    let time = 7;
    if (v === 3) {
      time = 31;
    }
    let { cid } = this.state.deviceInfo;
    const startTime = new Date() * 1 - (time - 1) * 24 * 60 * 60 * 1000;
    const endTime = new Date() * 1;
    const resultArr = await Promise.all([
      Service.statistics.countByDays({
        cids: [cid],
        startTime,
        endTime
      }),
      Service.statistics.countPersonDayTrend({
        cids: [cid],
        type: 0,
        days: time,
        privilege: 1
      }),
      Service.place.getDeviceStatisticByCid({
        cid,
        startTime,
        endTime
      })
    ]);
    this.setState({
      vehicleResource: resultArr[0].data,
      faceResource: resultArr[1].data.list,
      bodyResource: resultArr[1].data.list,
      totalResource: resultArr[2].data.list
    });
  };
  //抓拍资源统计
  countSnapResource = v => {
    let time = 7;
    if (v === 3) {
      time = 31;
    }
    let { cid } = this.state.deviceInfo;
    Service.place
      .getDeviceStatisticByCid({
        cid,
        startTime: new Date() * 1 - time * 24 * 60 * 60 * 1000,
        endTime: new Date() * 1
      })
      .then(res => {
        this.setState({
          snapResource: res.data.list
        });
      });
  };
  counResourceStatisticsTypeChange = v => {
    this.setState({ counResourceStatisticsType: v });
    this.counResourceStatistics(v);
  };
  countSnapResourceTypeChange = v => {
    this.setState({ countSnapResourceType: v });
    this.countSnapResource(v);
  };
  //选标签
  itemClick = value => {
    let { placeTags } = this.state;
    if (placeTags.indexOf(value) >= 0) {
      placeTags = placeTags.filter(v => {
        return v !== value;
      });
    } else {
      placeTags = placeTags.concat(value);
    }
    this.setState({
      placeTags
    });
  };
  handleLabelCancel = () => {
    this.setState({
      placeTags: this.state.initPlaceTags
    });
  };
  handleLabelOK = () => {
    let { placeId, placeTags } = this.state;
    Service.place
      .playPlaceTagsById({
        placeId,
        tags: placeTags
      })
      .then(v => {
        message.success("添加标签成功");
        this.queryPlaceInfo();
      });
  };
  onSelectDevice = data => {
    let {deviceInfo} = this.state
    if(deviceInfo.cid===data.cid){
      return
    }
    let igArr = ["103501", "103502", "118901", "100605"];
    if (igArr.indexOf(data.deviceType) > -1) {
      let item = Dict.map.deviceType.find(v =>
        v.value.split(",").includes(data.deviceType)
      );
      message.warn(`${item.label}档案功能正在开发中，敬请期待`);
      return;
    }
    
    this.setState({
      deviceInfo: data,
      countDeviceCapPersonsType: 2,
      counResourceStatisticsType: 2,
      countSnapResourceType: 2
    });
    this.getInitDeviceData(data);
  };
  render() {
    let {
      deviceList,
      placeList,
      loading,
      deviceInfo,
      resources,
      vehiclesResources,
      countDeviceCapPersonsData,
      countDeviceCapPersonsType,
      placeTags,
      initPlaceTags,
      nearDeviceList,
      vehicleResource,
      faceResource,
      bodyResource,
      counResourceStatisticsType,
      countSnapResourceType,
      snapResource,
      totalResource
    } = this.state;

    if (!loading && deviceList.length === 0) {
      return <NoData />;
    }

    const count = deviceList.length;
    const onlineCount = deviceList.filter(v => v.deviceStatus * 1 === 1).length;
    let deviceInfoData = Object.assign(
      {},
      deviceInfo,
      resources,
      vehiclesResources
    );
    return (
      <div className="device-file-view">
        <div className="device-file-nav">
          <div className="device-file-title">
            <div className="panel-name">设备档案</div>
            <div className="device-num-box">
              <div className="device-num-box-item">
                <div className="device-num-title">设备总数：</div>
                <div className="device-num primary-color">
                  {Utils.splitNum(count)}
                </div>
              </div>
              <div className="device-num-box-item">
                <div className="device-num-title">在线总数：</div>
                <div className="device-num device-online-num ">
                  {Utils.splitNum(onlineCount)}
                </div>
              </div>
            </div>
          </div>
          <div className="device-file-header" />
        </div>
        <Spin spinning={loading} wrapperClassName="device-file-content">
          <DeviceResouceTree
            onSelectDevice={this.onSelectDevice}
            deviceList={deviceList}
            deviceInfo={deviceInfo}
            placeList={placeList}
          />
          <div className="device-file-detail">
            <div className="device-content-view">
              <DeviceInfo data={deviceInfoData} />
              <PlaceLabel
                placeTags={placeTags}
                initPlaceTags={initPlaceTags}
                itemClick={this.itemClick}
                handleLabelCancel={this.handleLabelCancel}
                handleLabelOK={this.handleLabelOK}
              />
              <SnapResource
                snapResource={snapResource}
                type={countSnapResourceType}
                typeChange={this.countSnapResourceTypeChange}
              />
              <ResourceStatistics
                totalResource={totalResource}
                vehicleResource={vehicleResource}
                faceResource={faceResource}
                bodyResource={bodyResource}
                type={counResourceStatisticsType}
                typeChange={this.counResourceStatisticsTypeChange}
              />
              <VisitorsFlowrate
                data={countDeviceCapPersonsData}
                type={countDeviceCapPersonsType}
                typeChange={this.countDeviceCapPersonsTypeChange}
              />
              <DevicePoint point={deviceInfo} nearDeviceList={nearDeviceList} />
              <div className="footerView" />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default deviceFile;
