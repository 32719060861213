import React from "react";
import moment from "moment";
import { Radio } from "antd";
const RadioGroup = Radio.Group;
const selectTime = [{ value: 2, label: "近一周" }, { value: 3, label: "近一月" }];
const FrameCard = Loader.loadBusinessComponent("FrameCard");
const EchartsReact = Loader.loadBaseComponent('EchartsReact')
class SnapResource extends React.Component {
  change = e => {
    this.props.typeChange && this.props.typeChange(e.target.value);
  };
  getOtionTem() {
    let { snapResource, type } = this.props;
    let color = ["#FFAA00", "#5A60A2", "#B5BBC7"];
    let seriesName = ["图片总数", "已识别目标的图片", "未识别出目标的图片"];
    let xAxisData = [];
    let dataList = [[], [], []];
    let time = 7;
    if (type === 3) {
      time = 31;
    }

    for (let i = 0; i < time; i++) {
      let item = moment(new Date() * 1 - (time - i - 1) * 24 * 60 * 60 * 1000).format("YYYY-MM-DD");
      xAxisData.push(item);
      let snapResourceItem = snapResource.find(v => {
        return v.day === item;
      });
      dataList[0][i] = snapResourceItem ? snapResourceItem.total : 0;
      dataList[1][i] = snapResourceItem ? snapResourceItem.imgTotal : 0;
      dataList[2][i] = snapResourceItem ? snapResourceItem.imgNoneTotal : 0;
    }
    if (type === 3) {
      xAxisData = xAxisData.map(v => {
        let item = Number(v.substring(v.length - 2, v.length));
        if (item === 1) {
          return (v = `${Number(v.substring(v.length - 5, v.length - 3))}月`);
        } else {
          return (v = item);
        }
      });
    }
    let series = [];
    seriesName.map((v, k) => {
      series.push({
        name: v,
        type: "bar",
        lineStyle: {
          color: color[k]
        },
        barWidth: type === 2 ? 8 : 2,
        barGap: 1,
        itemStyle: {
          normal: {
            color: color[k]
          }
        },
        data: dataList[k]
      });
      return series;
    });
    const option = {
      color: color,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
          shadowStyle: {
            color: "rgba(136,153,187,0.10)"
          }
        },
        confine: true
      },
      legend: {
        icon: "roundRect",
        itemGap: 48,
        itemWidth: 18,
        itemHeight: 3,
        bottom: 15,
        data: seriesName
      },
      grid: {
        left: 60,
        right: 60,
        bottom: 70,
        top: 50
      },
      xAxis: {
        type: "category",
        splitNumber: 24,
        boundaryGap: ["20%", "20%"],
        axisTick: true,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 2
          }
        },
        axisLabel: {
          color: "#666",
          fontSize: 12,
          fontFamily: "Microsoft YaHei"
        },
        axisLine: {
          show: false
        },
        data: xAxisData
      },
      yAxis: {
        type: "value",
        axisTick: false,
        name: "数量",
        axisLine: {
          show: false
        },
        axisLabel: {
          color: "#666",
          fontSize: 12,
          fontFamily: "Microsoft YaHei"
        },
        splitLine: {
          show: false
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ["#F3F6F9", "#fff"]
          }
        }
      },
      series: series
    };

    return option;
  }
  render() {
    let { type } = this.props;
    return (
      <FrameCard
        title="抓拍资源统计："
        headerOperator={
          <RadioGroup
            options={selectTime.filter(v => {
              return v.value !== 1;
            })}
            onChange={this.change}
            value={type}
          />
        }
      >
        <div className="visitors-flowrate-info">
          <EchartsReact option={this.getOtionTem()} style={{ width: "100%", height: "100%" }} />
        </div>
      </FrameCard>
    );
  }
}

export default SnapResource;
