import React from 'react';
import DragContent from './DragContent';
import { Button, Select} from 'antd';
import '../style/device-content.less';

const Option = Select.Option;
const ListComponent = Loader.loadBusinessComponent('ListComponent');
const PlaceTree = Loader.loadBaseComponent('PlaceTree');
const IconFont = Loader.loadBaseComponent('IconFont');

class DeviceContent extends React.Component {
  constructor(props) {
    super(props);
    this.groupNames = [];
    this.deviceExts = [];
    this.setDeviceExt(this.props.deviceList);
    this.listRef = React.createRef();
    this.state = {
      showOrgLayout: true,
      showDeviceLayout: true,
      selectOrg: null,
      point: null,
      visible: false,
      currentId: null,
      activePlaceId: null,
    };
  }

  domPrefix = Math.random().toString().split('.')[1];

  componentDidMount() {
    SocketEmitter.on(SocketEmitter.eventName.editDeviceInfo, this.updateListForceTimeout)
  }

  componentWillReceiveProps(nextProps) {
    this.setDeviceExt(nextProps.deviceList);
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.editDeviceInfo, this.updateListForceTimeout)
  }

  updateListForceTimeout = () => {
    setTimeout(() => {
      this.updateListForce()
    }, 0);
  }

  /**
   * @desc 强制更新大列表
   * @param {*} deviceList 
   */
  updateListForce = () => {
    this.listRef.current.forceUpdateGrid();
  }
  /**
   * @desc 补充设备列表状态
   * @param {array} deviceList
   */
  setDeviceExt(deviceList) {
    let arr = [];
    for (let i = 0, l = deviceList.length; i < l; i++) {
      let item = deviceList[i];
      let ext = this.deviceExts.find(v => v.id === item.id);
      if (!ext) {
        arr.push({ id: item.id, visible: false });
      } else {
        arr.push(ext);
      }
    }
    this.deviceExts = arr;
  }

  /**
   * @desc 显示和隐藏组织和设备部分布局
   */
  changeLayoutStatus = (isDevice, flag) => {
    if (isDevice) {
      this.setState({ showDeviceLayout: flag });
    } else {
      this.setState({ showOrgLayout: flag });
    }
  };

  /**
   * @desc 计算当前render 设备列表中 各个设备状态的统计
   * @param {Array} deviceList
   */
  computedListStatus(deviceList) {
    let onlineList = [],
      offlineList = [];
    for (let i = 0, l = deviceList.length; i < l; i++) {
      let item = deviceList[i];
      if (item.deviceStatus * 1 === 0) {
        offlineList.push(item);
      } else {
        onlineList.push(item);
      }
    }
    return {
      list: onlineList.concat(offlineList),
      onlineCount: onlineList.length,
      offlineCount: offlineList.length
    };
  }

  /**
   * @desc 选中行政区划
   */
  onSelectPlace = (ids) => {
    let { activePlaceId } = this.state;
    activePlaceId = activePlaceId === ids[0] ? null : ids[0];
    this.setState({ activePlaceId });
    this.props.changePlace && this.props.changePlace(activePlaceId);
    this.updateListForce();
  };

  renderGroup = (item, index) => {
    const { collectionList } = this.props;
    return (
      <div
        className={`device-to-group ${
          this.layoutGroupUp ? 'device-to-group-up' : ''
        }`}
        onClick={e => Utils.stopPropagation(e)}
      >
        <div className="group-select-title">{item.deviceName}</div>
        <div className={`group-select-${item.id} group-select-${item.id}-${this.domPrefix}`} />
        <Select
          placeholder="请选择分组"
          mode="multiple"
          onChange={v => this.changeGroup(v, item)}
          className="group-select-com"
          value={this.groupNames}
          getPopupContainer={() =>
            document.querySelector(`.group-select-${item.id}-${this.domPrefix}`)
          }
        >
          {collectionList.map(v => (
            <Option key={v.groupName} value={v.groupName}>
              {v.groupName}
            </Option>
          ))}
        </Select>
        <div className="group-btn">
          <Button size="small" onClick={() => this.cancelGroup(index)}>
            取消
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => this.subGroup(item, index)}
          >
            确定
          </Button>
        </div>
      </div>
    );
  };
  renderLoopBtn = (node, type) => {
    const { isMapMode, showLoopSettingLayout } = this.props;
    const hasLiving = !!BaseStore.menu.getInfoByName('realVideo');
    if(isMapMode || !hasLiving) {
      return null
    }
    return (
      <span
        className="video-lx"
        title="视频轮巡"
        onClick={e => {
          Utils.stopPropagation(e);
          showLoopSettingLayout(node, false, type);
        }}
      >
        <IconFont type="icon-S_Edit_RoundPlay" />
      </span>
    )
  }
  render() {
    const { 
      showOrgLayout, showDeviceLayout, activePlaceId
    } = this.state;
    const {
      deviceList,
      selectDevice,
      onSelectDevice,
      placeList,
      keyword
    } = this.props;
    const result = this.computedListStatus(deviceList);
    const placeTreeData = Utils.computTreeList(placeList)
    return (
      <div className="org-tree-with-device-layer">
        <div className="org-tree-part">
          <div className="title-part">
            <div
              className="title-part-left fl"
              onClick={() => this.changeLayoutStatus(false, !showOrgLayout)}
            >
              <IconFont
                type={
                  showOrgLayout
                    ? 'icon-S_Arrow_SmallUp'
                    : 'icon-S_Arrow_SmallDown'
                }
              />
              场所列表
            </div>
          </div>
          <PlaceTree
            showCount={true}
            className={!showOrgLayout ? 'hide-tree' : ''}
            activeKey={[activePlaceId]}
            onSelect={this.onSelectPlace}
            treeData={placeTreeData}
            selfTreeData={true}
            // renderSuffix={node => this.renderLoopBtn(node, 'place')}
            // treeNodeClass={node => node.id === loopOrgInfo.id ? 'loop-org' : ''}
          />
        </div>
        <DragContent
          className={`${!showOrgLayout ? 'no-darg-height' : ''} ${
            !showDeviceLayout ? 'hide-darg-height' : ''
          }`}
          disabled={!showOrgLayout || !showDeviceLayout}
        >
          <div className="device-list-part" ref={this.deviceListRef}>
            <div
              className="title-part"
              onClick={() => this.changeLayoutStatus(true, !showDeviceLayout)}
            >
              <IconFont
                type={
                  showDeviceLayout
                    ? 'icon-S_Arrow_SmallUp'
                    : 'icon-S_Arrow_SmallDown'
                }
              />
              设备列表
              <span className="count-part">
                <span className="count-part-on">{result.onlineCount}</span>
                <span className="count-part-off">{result.offlineCount}</span>
              </span>
            </div>
            <div
              className={`device-list-content ${
                !showDeviceLayout ? 'hide' : ''
              }`}
            >
              <ListComponent
                ref={this.listRef}
                listData={result.list}
                keyword={keyword}
                selectItemClass="active"
                onClick={onSelectDevice}
                selectList={[selectDevice]}
              />
            </div>
          </div>
        </DragContent>
      </div>
    );
  }
}
export default DeviceContent;
