(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("moment"), require("mobxReact"), require("ReactRouterDOM"), require("_"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define("deviceFile", ["React", "antd", "moment", "mobxReact", "ReactRouterDOM", "_", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["deviceFile"] = factory(require("React"), require("antd"), require("moment"), require("mobxReact"), require("ReactRouterDOM"), require("_"), require("mobx"));
	else
		root["deviceFile"] = factory(root["React"], root["antd"], root["moment"], root["mobxReact"], root["ReactRouterDOM"], root["_"], root["mobx"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__9__) {
return 